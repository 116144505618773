import React, { FunctionComponent } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import OnScreen from "../components/OnScreen"
import SEO from "../components/SEO"

const Accomplishments: FunctionComponent = () => (
  <Layout>
    <SEO
      description="See Will Moore's Accomplishments. Find out how Will Moore gamified his life to accomplish a lot of things in life"
      title="Awards & Accomplishments"
      link="https://mooremomentum.com/accomplishments/"
    />

    <div className="bg-blue mt-4">
      <div className="container mx-auto px-4">
        <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
          Awards &amp; Accomplishments
        </h1>
      </div>
    </div>

    <div className="max-w-4xl mx-auto my-8 px-4">
      <div className="gap-8 grid grid-cols-1 sm:grid-cols-2">
        <OnScreen className="sm:col-span-2" classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.rollins.edu/college-of-liberal-arts/news/lighting-the-way/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Mentor Giving Guidance"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/lighting-the-way.jpg"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Article</p>
              <p>Lighting the Way</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://table.skift.com/2018/12/12/waitr-acquires-bite-squad-for-321-million/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="The Aquisition of Bite"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-2.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Article</p>
              <p>2019 Sale Of Business/Exit</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.youtube.com/watch?v=UEwilrcj3WM"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Doorstep's Hope Bar Program Press Release"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-3.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">News Interview</p>
              <p>Doorstep&apos;s Hope Bar Program Feeding Starving Children</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.youtube.com/watch?v=Gd7RgmBaWh8"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Delivering Food To The Homeless Press Release"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-4.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">News Piece</p>
              <p>Delivering Food to The Homeless</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.bizjournals.com/orlando/print-edition/2015/06/12/how-one-firm-is-dominating-orlando-s-food-delivery.html"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Dominating The Food Delivery Industry"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-5.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Article</p>
              <p>How One Firm is Dominating The Food Delivery Industry</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.youtube.com/watch?v=32dRKrMQPTw"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Interview with Doorstep Founders"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-6.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Interview</p>
              <p>w/ Doorstep Founders</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.rollins.edu/college-of-liberal-arts/news/the-man-behind-doorstep-delivery/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Interview With Will Moore"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-7.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Article</p>
              <p>The Man Behind Doorstep Delivery</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.orlandosentinel.com/business/os-doorstep-delivery-bite-squad-20170329-story.html"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Dominating The Food Delivery Industry"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-8.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Article</p>
              <p>Orlando-based Doorstep Delivery merges with Bite Squad</p>
            </div>
          </a>
        </OnScreen>

        <OnScreen classToAdd="scale-in-center">
          <a
            className="bg-white flex flex-col h-full max-w-max mx-auto p-4 rounded-md shadow-lg transition-opacity hover:opacity-70"
            href="https://www.businesswire.com/news/home/20181212005245/en/Waitr-Holdings-Acquire-Bite-Squad"
            rel="noreferrer noopener"
            target="_blank"
          >
            <StaticImage
              alt="Dominating The Food Delivery Industry"
              className="flex-1 mb-4"
              placeholder="blurred"
              src="../images/awards-9.png"
            />

            <div className="text-center">
              <p className="font-Orbitron text-xl">Article</p>
              <p>Waitr Holdings Inc. to Acquire Bite Squad</p>
            </div>
          </a>
        </OnScreen>
      </div>
    </div>
  </Layout>
)

export default Accomplishments
